import _ from "lodash";
import { useMemo } from "react";

import { Experience } from "../../quiz/experience";
import { Gender } from "../../quiz/gender";
import { Goals } from "../../quiz/goals";
import { User } from "../../types";
import { ProgramLevelTitle, ProgramLevelProps } from "./ProgramLevel.types";
import { FourMonthProgramLevelProps } from "./FourMonthProgramLevel.types";
import { YearlyProgramLevelProps } from "./YearlyProgramLevel.types";


import { MonthlyYearlyProgramLevelProps } from "./MonthlyYearlyProgramLevel.types";
import { CDN_ROOT } from "../images";

type LandingPageConfig = {
  gender: Gender;
  goal: ReadonlyArray<Goals>;
  experience: Experience;
  name: string;
  listName: string;
};

type PlanConfig = {
  productId: string;
  name: string;
  image: string;
  buyLink: string;
};

type ProgramLevelConfig = {
  base: PlanConfig;
  premium: PlanConfig;
  elite: PlanConfig;
} & LandingPageConfig;

function getConfigForUser<T extends LandingPageConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  return _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience
  );
}

type ProgramNames = (typeof PROGRAM_LEVEL_CONFIG)[number]["name"];
// type ProgramDurationNames = typeof PROGRAM_DURATION_CONFIG[number]['name'];

function getProgramNameForUser(user: User): ProgramNames {
  const configItem = getConfigForUser(user, PROGRAM_LEVEL_CONFIG);
  if (configItem) {
    return configItem.name;
  }

  throw new Error("No configuration found for the given user");
}

const PROGRAM_LEVEL_CONFIG = [
  {
    gender: Gender.Male,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Male Beginner Build",
    listName: "Beginner build program plus",
    base: {
      name: "Beginner-BUILD 2.0 Main - Base",
      productId: "8018453856504",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903134761208:1?channel=buy_button",
    },
    premium: {
      name: "Beginner-BUILD 2.0 Main - Premium",
      productId: "8018561106168",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903535481080:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Beginner-BUILD 2.0 Main - Elite",
      productId: "8018562515192",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903546032376:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Male,
    goal: [Goals.BuildMuscle],
    experience: Experience.Intermediate,
    name: "Male Intermediate Build",
    listName: "Intermediate build program plus",
    base: {
      name: "Intermediate-BUILD 2.0 Main - Base",
      productId: "8019186942200",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905811349752:1?channel=buy_button",
    },
    premium: {
      name: "Intermediate-BUILD 2.0 Main - Premium",
      productId: "8019187335416",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905813250296:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Intermediate-BUILD 2.0 Main - Elite",
      productId: "8019187433720",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905813807352:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Male,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    experience: Experience.Beginner,
    name: "Male Beginner Shred",
    listName: "Beginner shred program plus",
    base: {
      name: "Beginner-SHRED 2.0 Main - Base",
      productId: "8018452480248",
      image: `${CDN_ROOT}/images/landingPage/MaleShredBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903131091192:1?channel=buy_button",
    },
    premium: {
      name: "Beginner-SHRED 2.0 Main - Premium",
      productId: "8018560647416",
      image: `${CDN_ROOT}/images/landingPage/MaleShredPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903534760184:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Beginner-SHRED 2.0 Main - Elite",
      productId: "8018562580728",
      image: `${CDN_ROOT}/images/landingPage/MaleShredElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903546294520:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Male,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    experience: Experience.Intermediate,
    name: "Male Intermediate Shred",
    listName: "Intermediate shred program plus",
    base: {
      name: "Intermediate-SHRED 2.0 Main - Base",
      productId: "8019185697016",
      image: `${CDN_ROOT}/images/landingPage/MaleShredBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905799258360:1?channel=buy_button",
    },
    premium: {
      name: "Intermediate-SHRED 2.0 Main - Premium",
      productId: "8019186221304",
      image: `${CDN_ROOT}/images/landingPage/MaleShredPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905804337400:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Intermediate-SHRED 2.0 Main - Elite",
      productId: "8019186516216",
      image: `${CDN_ROOT}/images/landingPage/MaleShredElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905807253752:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Female Beginner Strong",
    listName: "Beginner strong program plus",
    base: {
      name: "Beginner-STRONG 2.0 Main - Base",
      productId: "8018560352504",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903533515000:1?channel=buy_button",
    },
    premium: {
      name: "Beginner-STRONG 2.0 Main - Premium",
      productId: "8018561990904",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903540035832:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Beginner-STRONG 2.0 Main - Elite",
      productId: "8018562121976",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903543378168:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Intermediate,
    name: "Female Intermediate Strong",
    listName: "Intermediate strong program plus",
    base: {
      name: "Intermediate-STRONG 2.0 Main - Base",
      productId: "8019187499256",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905814167800:1?channel=buy_button",
    },
    premium: {
      name: "Intermediate-STRONG 2.0 Main - Premium",
      productId: "8019677118712",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905815085304:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Intermediate-STRONG 2.0 Main - Elite",
      productId: "8019187728632",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905815150840:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    experience: Experience.Beginner,
    name: "Female Beginner Lean",
    listName: "Beginner lean program plus",
    base: {
      name: "Beginner-LEAN 2.0 Main - Base",
      productId: "8018454249720",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903137448184:1?channel=buy_button",
    },
    premium: {
      name: "Beginner-LEAN 2.0 Main - Premium",
      productId: "8018561859832",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903539511544:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Beginner-LEAN 2.0 Main - Elite",
      productId: "8018562318584",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43903545213176:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    experience: Experience.Intermediate,
    name: "Female Intermediate Lean",
    listName: "Intermediate lean program plus",
    base: {
      name: "Intermediate-LEAN 2.0 Main - Base",
      productId: "8019188252920",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanBase.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905817477368:1?channel=buy_button",
    },
    premium: {
      name: "Intermediate-LEAN 2.0 Main - Premium",
      productId: "8019187695864",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanPremium.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905815085304:1,43243243340024:1?channel=buy_button",
    },
    elite: {
      name: "Intermediate-LEAN 2.0 Main - Elite",
      productId: "8019190677752",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanElite.jpg`,
      buyLink:
        "https://shop.builtwithscience.com/cart/43905829404920:1,43243243340024:1,43491372663032:1?channel=buy_button",
    },
  },
] as const;

const basicFeatures = (mealText: string) => [
  { text: "Step-By-Step Training & Nutrition Plan" },
  { text: "Gym & Home Workouts" },
  { text: "Private Facebook Community" },
  { text: "Lifetime Access" },
  { text: "30 Day Money Back Guarantee", bold: true },
  { text: `50 ${mealText} Meals`, bold: true },
  { text: "1 Weekly Meal Plan", bold: true },
  { text: "3 Workout Programs (3-6 months worth of workouts)", bold: true },
  { text: "23 Exercise Tutorial Videos (main exercises only)", bold: true },
];

const premiumFeatures = (mealText: string) => [
  { text: "One-time payment for lifetime access" },
  { text: "Step-By-Step Training & Nutrition Plan" },
  { text: "Gym & Home Workouts" },
  { text: "Private Facebook Community" },
  { text: "Lifetime Access" },
  { text: "60 Day Money Back Guarantee", bold: true },
  { text: `100 ${mealText} Meals`, bold: true },
  { text: "20 Weekly Meal Plan", bold: true },
  { text: "10 Workout Programs (6-12 months worth of workouts)", bold: true },
  {
    text: "60 Exercise Tutorial Videos (main exercises + some alternatives)",
    bold: true,
  },
  { text: "Ab Workout Routine" },
  { text: "Bonus Training + Nutrition Videos" },
  { text: "Complimentary BWS Shaker Bottle" },
];

const eliteFeatures = (mealText: string) => [
  { text: "One-time payment for lifetime access", bold: false },
  { text: "Step-By-Step Training & Nutrition Plan", bold: false },
  { text: "Gym & Home Workouts", bold: false },
  { text: "Private Facebook Community", bold: false },
  { text: "Lifetime Access", bold: false },
  { text: "90 Day Money Back Guarantee", bold: true },
  { text: `200+ ${mealText} Meals`, bold: true },
  { text: "48 Weekly Meal Plans", bold: true },
  { text: "15 Workout Programs (12-24 months worth of workouts)", bold: true },
  {
    text: "102 Exercise Tutorial Videos (main exercises + all alternatives)",
    bold: true,
  },
  { text: "Ab Workout Routine", bold: false },
  { text: "Bonus Training + Nutrition Videos", bold: false },
  { text: "Complimentary BWS Shaker Bottle", bold: false, isBlue: true },
  { text: "30-Minute Time-Saving Workouts", bold: false, isBlue: true },
  { text: "30-Minute Weekly Meal Prep Guide", bold: false, isBlue: true },
  { text: "5-Minute-Meal Recipes", bold: false, isBlue: true },
  { text: "Budget-Friendly Meal Prep Guide", bold: false, isBlue: true },
  { text: "Travel Guide", bold: false, isBlue: true },
  { text: "Automatic Grocery List Generator", bold: false, isBlue: true },
];

type ProgramLevels = {
  base: ProgramLevelProps;
  premium: ProgramLevelProps;
  elite: ProgramLevelProps;
};

const useProgramLevelConfigs = (
  user: User
): ProgramLevels & { listName: string } => {
  const { base, premium, elite, listName } = useMemo(() => {
    const configItem = getConfigForUser<ProgramLevelConfig>(
      user,
      PROGRAM_LEVEL_CONFIG
    );
    if (configItem) {
      const { base, premium, elite } = configItem;
      const mealText =
        configItem.name.includes("Build") || configItem.name.includes("Strong")
          ? "BUILD"
          : "SHRED";
      return {
        base: {
          ...base,
          title: ProgramLevelTitle.Base,
          price: {
            current: 67,
            original: 67,
          },
          features: basicFeatures(mealText),
          listName: configItem.listName,
          mobileOrder: 3,
        },
        premium: {
          ...premium,
          title: ProgramLevelTitle.Premium,
          price: {
            current: 119,
            original: 159,
          },
          features: premiumFeatures(mealText),
          listName: configItem.listName,
          headline: {
            title: "Most Popular",
            color: "#596DD3",
            background: "#FFFFFF",
          },
          mobileOrder: 2,
        },
        elite: {
          ...elite,
          title: ProgramLevelTitle.Elite,
          price: {
            current: 149,
            original: 249,
          },
          features: eliteFeatures(mealText),
          listName: configItem.listName,
          headline: {
            title: "Best Value",
            color: "#FFFFFF",
            background: "#596DD3",
          },
          mobileOrder: 1,
        },
        listName: configItem.listName,
        mealText,
      };
    }

    // If no configItem is found, we could either return a default object or throw an error
    throw new Error("No configuration found for the given user");
  }, [user]);
  return { base, premium, elite, listName };
};

const LANDING_PAGE_CHOICES = _.map(PROGRAM_LEVEL_CONFIG, (item) => item.name);

const STRIPE_CHECKOUT_SESSION_URL = "https://zk1gl7u496.execute-api.us-west-2.amazonaws.com/production/public/stripe/checkout"

//4month links


const FOUR_MONTH_TEST_PROGRAM_CONFIG = [
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.BuildMuscle],
    name: 'Male Beginner Build',
    listName: 'Beginner build program plus',
    base: {
      productId: "8159849545976",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/beginner-unlock`,
      name: "4 Month Personalized Base Plan - Build",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildBase.jpg`,
    },
    premium: {
      productId: "8159851544824",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/beginner-unlock`,
      name: "4 Month Personalized Premium Plan - Build",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildPremium.jpg`,
    },
    elite: {
      productId: "8159855902968",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/beginner-unlock`,
      name: "4 Month Personalized Elite Plan - Build",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Beginner Lean',
    listName: 'Beginner lean program plus',
    base: {
      productId: "8159927697656",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/beginner-unlock`,
      name: "4 Month Personalized Base Plan - Lean",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanBase.jpg`,
    },
    premium: {
      productId: "8159942967544",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/beginner-unlock`,
      name: "4 Month Personalized Premium Plan - Lean",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanPremium.jpg`,
    },
    elite: {
      productId: "8159945556216",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/beginner-unlock`,
      name: "4 Month Personalized Elite Plan - Lean",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Female Beginner Strong",
    listName: "Beginner strong program plus",
    base: {
      name: "4 Month Personalized Base Plan - Strong",
      productId: "8160113131768",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongBase.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/beginner-unlock`,
    },
    premium: {
      name: "4 Month Personalized Premium Plan - Strong",
      productId: "8018561990904",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongPremium.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/beginner-unlock`,
    },
    elite: {
      name: "4 Month Personalized Elite Plan - Strong",
      productId: "8018562121976",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongElite.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/beginner-unlock`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Beginner Shred',
    listName: 'Beginner shred program plus',
    base: {
      productId: "8159897092344",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/beginner-unlock`,
      name: "4 Month Personalized Base Plan - Shred",
      image: `${CDN_ROOT}/images/landingPage/MaleShredBase.jpg`,
    },
    premium: {
      productId: "8159898042616",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/beginner-unlock`,
      name: "4 Month Personalized Plan - Shred",
      image: `${CDN_ROOT}/images/landingPage/MaleShredPremium.jpg`,
    },
    elite: {
      productId: "8159898697976",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/beginner-unlock`,
      name: "8 Month Personalized Plan - Shred",
      image: `${CDN_ROOT}/images/landingPage/MaleShredElite.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Male Intermediate Build',
    listName: 'Intermediate build program plus',
    base: {
      productId: "8159889096952",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/intermediate-unlock`,
      name: "4 Month Personalized Base Plan - Build.",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildBase.jpg`,
    },
    premium: {
      productId: "8159888179448",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/intermediate-unlock`,
      name: "4 Month Personalized Premium Plan - Build.",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildPremium.jpg`,
    },
    elite: {
      productId: "8159880642808",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/intermediate-unlock`,
      name: "4 Month Personalized Elite Plan - Build.",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Intermediate Lean',
    listName: 'Intermediate lean program plus',
    base: {
      productId: "8160022364408",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/intermediate-unlock`,
      name: "4 Month Personalized Base Plan - Lean.",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanBase.jpg`,
    },
    premium: {
      productId: "8160015450360",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/intermediate-unlock`,
      name: "4 Month Personalized Premium Plan - Lean.",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanPremium.jpg`,
    },
    elite: {
      productId: "8159958040824",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/intermediate-unlock`,
      name: "4 Month Personalized Elite Plan - Lean.",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Female Intermediate Strong',
    listName: 'Intermediate strong program plus',
    base: {
      productId: "8160120242424",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/intermediate-unlock`,
      name: "4 Month Personalized Base Plan - Strong.",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongBase.jpg`,
    },
    premium: {
      productId: "8160119390456",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/intermediate-unlock`,
      name: "4 Month Personalized Premium Plan - Strong.",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongPremium.jpg`,
    },
    elite: {
      productId: "8160117850360",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/intermediate-unlock`,
      name: "4 Month Personalized Elite Plan - Strong.",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongElite.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Intermediate Shred',
    listName: 'Intermediate shred program plus',
    base: {
      productId: "8159920324856",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-base/intermediate-unlock`,
      name: "4 Month Personalized Base Plan - Shred.",
      image: `${CDN_ROOT}/images/landingPage/MaleShredBase.jpg`,
    },
    premium: {
      productId: "8159919407352",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-premium/intermediate-unlock`,
      name: "4 Month Personalized Premium Plan - Shred.",
      image: `${CDN_ROOT}/images/landingPage/MaleShredPremium.jpg`,
    },
    elite: {
      productId: "8159917965560",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/4-month-elite/intermediate-unlock`,
      name: "4 Month Personalized Elite Plan - Shred.",
      image: `${CDN_ROOT}/images/landingPage/MaleShredElite.jpg`,
    },
  }
] as const;

const fourMonthBaseFeatures = [
  "4 Month Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**3** Customized Workout Plans",
  "**23** Exercise Tutorial Videos (main exercises only)",
  "**50** Shred Meals",
  "**1** Weekly Meal Plan",
  "Daily Guidance & Habit Building",
  "Private Facebook Community",
] as const;

const fourMonthPremiumFeatures = [
  "4 Month Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**10** Customized Workout Plans",
  "**60** Exercise Tutorial Videos (main exercises + some alternatives)",
  "**100** Shred Meals",
  "**20** Weekly Meal Plans",
  "**Weekly** Check-ins",
  "Daily Guidance & Habit Building",
  "Private Facebook Community",
] as const;

const fourMonthPremiumBonusFeatures = [
  "6-Pack Abs Sculpting Routine",
  "Bulletproof Joints & Prehab Routine",
  "Complimentary BWS Shaker Bottle",
] as const;

const fourMonthEliteFeatures = [
  "4 Month Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**15** Customized Workout Plans",
  "**102** Exercise Tutorial Videos (main exercises + all alternatives)",
  "**200+** Shred Meals",
  "**48** Weekly Meal Plans",
  "**Weekly** Check-ins",
  "Daily Guidance & Habit Building",
  "Private Facebook Community",
] as const;

const fourMonthEliteBonusFeatures = [
  "6-Pack Abs Sculpting Routine",
  "Bulletproof Joints & Prehab Routine",
  "Complimentary BWS Shaker Bottle",
  "Personalized Progressive Overload Recommendations",
  "30-Minute Time-Saving Workouts",
  "5-Minute Meal Recipes",
  "Budget-Friendly Meal Prep Guide",
  "Automatic Grocery List Generator",
  "Bonus Travel Workouts & Nutrition Tips",
] as const;

type FourMonthProgramLevels = {
  base: FourMonthProgramLevelProps;
  premium: FourMonthProgramLevelProps;
  elite: FourMonthProgramLevelProps;
}

function getStripeConfigForUser<T extends ProgramLevelConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  const configItem = _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience 
  );
  if (!!configItem) {
    // if the user is overweight and wants to lose weight or lose fat and build muscle, we show them the beginner program
    if (user.bodytype === 'Overweight' && [Goals.LoseFat, Goals.BuildMuscleAndLoseFat].includes(user.goals)) {
      return {
        ...configItem,
        base: { 
          ...configItem.base,
          buyLink: configItem.base.buyLink.replace('intermediate-unlock', 'beginner-unlock')
        },
        premium: { 
          ...configItem.premium,
          buyLink: configItem.premium.buyLink.replace('intermediate-unlock', 'beginner-unlock')
        },
        elite: {
          ...configItem.elite,
          buyLink: configItem.elite.buyLink.replace('intermediate-unlock', 'beginner-unlock')
        }
      };
    }
  }
  return configItem;
}

const useFourMonthProgramLevelConfigs = (user: User): FourMonthProgramLevels & { listName: string } => {
  const configItem = getStripeConfigForUser<ProgramLevelConfig>(
    user,
    FOUR_MONTH_TEST_PROGRAM_CONFIG
  );
  if (!configItem) {
    throw new Error("No configuration found for the given user");
  }
  const { base, premium, elite } = configItem;
  return {
    base: {
      ...base,
      title: ProgramLevelTitle.Base,
      price: {
        current: 67,
        original: 67,
        durationWeeks: 8,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 7,
      featureHeader: "Getting started:",
      features: {
        basic: fourMonthBaseFeatures,
        bonus: [],
      },
      mobileOrder: 3,
    },
    premium: {
      ...premium,
      title: ProgramLevelTitle.Premium,
      price: {
        current: 119,
        original: 159,
        durationWeeks: 16,
      },
      listName: configItem.listName,
      headline: {
        title: "Most Popular",
        color: "#596DD3",
        background: "#FFFFFF",
      },
      guaranteeDurationDays: 14,
      featureHeader: "Great results:",
      features: {
        basic: fourMonthPremiumFeatures,
        bonus: fourMonthPremiumBonusFeatures,
      },
      mobileOrder: 2,
    },
    elite: {
      ...elite,
      title: ProgramLevelTitle.Elite,
      price: {
        current: 149,
        original: 249,
        durationWeeks: 32,
      },
      listName: configItem.listName,
      headline: {
        title: "Best Value",
        color: "#FFFFFF",
        background: "#596DD3",
      },
      guaranteeDurationDays: 30,
      featureHeader: "Best results & lifelong habits:",
      features: {
        basic: fourMonthEliteFeatures,
        bonus: fourMonthEliteBonusFeatures,
      },
      mobileOrder: 1,
    },
    listName: configItem.listName,
  };
};


// Yearly Links



const YEARLY_TEST_PROGRAM_CONFIG = [
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.BuildMuscle],
    name: 'Male Beginner Build',
    listName: 'Beginner build program plus',
    base: {
      productId: "8159849545976",
      buyLink:
        `https://shop.builtwithscience.com/cart/43903134761208:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Build",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildBase.jpg`,
    },
    premium: {
      productId: "8159851544824",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Premium Plan - Build",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildPremium.jpg`,
    },
    elite: {
      productId: "8159855902968",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Elite Plan - Build",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Beginner Lean',
    listName: 'Beginner lean program plus',
    base: {
      productId: "8159927697656",
      buyLink:
        `https://shop.builtwithscience.com/cart/43903137448184:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Lean",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanBase.jpg`,
    },
    premium: {
      productId: "8159942967544",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Premium Plan - Lean",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanPremium.jpg`,
    },
    elite: {
      productId: "8159945556216",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Elite Plan - Lean",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Female Beginner Strong",
    listName: "Beginner strong program plus",
    base: {
      name: "Yearly Personalized Base Plan - Strong",
      productId: "8160113131768",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongBase.jpg`,
      buyLink:
        `https://shop.builtwithscience.com/cart/43903533515000:1?channel=buy_button`,
    },
    premium: {
      name: "Yearly Personalized Premium Plan - Strong",
      productId: "8018561990904",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongPremium.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
    },
    elite: {
      name: "Yearly Personalized Elite Plan - Strong",
      productId: "8018562121976",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongElite.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Beginner Shred',
    listName: 'Beginner shred program plus',
    base: {
      productId: "8159897092344",
      buyLink:
        `https://shop.builtwithscience.com/cart/43903131091192:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Shred",
      image: `${CDN_ROOT}/images/landingPage/MaleShredBase.jpg`,
    },
    premium: {
      productId: "8159898042616",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Plan - Shred",
      image: `${CDN_ROOT}/images/landingPage/MaleShredPremium.jpg`,
    },
    elite: {
      productId: "8159898697976",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Plan - Shred",
      image: `${CDN_ROOT}/images/landingPage/MaleShredElite.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Male Intermediate Build',
    listName: 'Intermediate build program plus',
    base: {
      productId: "8159889096952",
      buyLink:
        `https://shop.builtwithscience.com/cart/43905811349752:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Build.",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildBase.jpg`,
    },
    premium: {
      productId: "8159888179448",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Premium Plan - Build.",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildPremium.jpg`,
    },
    elite: {
      productId: "8159880642808",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Elite Plan - Build.",
      image: `${CDN_ROOT}/images/landingPage/MaleBuildElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Intermediate Lean',
    listName: 'Intermediate lean program plus',
    base: {
      productId: "8160022364408",
      buyLink:
        `https://shop.builtwithscience.com/cart/43905817477368:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Lean.",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanBase.jpg`,
    },
    premium: {
      productId: "8160015450360",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Premium Plan - Lean.",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanPremium.jpg`,
    },
    elite: {
      productId: "8159958040824",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Elite Plan - Lean.",
      image: `${CDN_ROOT}/images/landingPage/FemaleLeanElite.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Female Intermediate Strong',
    listName: 'Intermediate strong program plus',
    base: {
      productId: "8160120242424",
      buyLink:
        `https://shop.builtwithscience.com/cart/43905814167800:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Strong.",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongBase.jpg`,
    },
    premium: {
      productId: "8160119390456",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Premium Plan - Strong.",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongPremium.jpg`,
    },
    elite: {
      productId: "8160117850360",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Elite Plan - Strong.",
      image: `${CDN_ROOT}/images/landingPage/FemaleStrongElite.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Intermediate Shred',
    listName: 'Intermediate shred program plus',
    base: {
      productId: "8159920324856",
      buyLink:
        `https://shop.builtwithscience.com/cart/43905799258360:1?channel=buy_button`,
      name: "Yearly Personalized Base Plan - Shred.",
      image: `${CDN_ROOT}/images/landingPage/MaleShredBase.jpg`,
    },
    premium: {
      productId: "8159919407352",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-premium/intermediate-unlock`,
      name: "Yearly Personalized Premium Plan - Shred.",
      image: `${CDN_ROOT}/images/landingPage/MaleShredPremium.jpg`,
    },
    elite: {
      productId: "8159917965560",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-elite/intermediate-unlock`,
      name: "Yearly Personalized Elite Plan - Shred.",
      image: `${CDN_ROOT}/images/landingPage/MaleShredElite.jpg`,
    },
  }
] as const;



const yearlyBaseFeatures = [
  "Step-By-Step Training & Nutrition Plan", 
  "Gym & Home Workouts",
  "Private Facebook Community",
  
  "**50** BUILD Meals",
  
  
  "**3** Workout Programs (3-6 months worth of workouts)",
  "**23** Exercise Tutorial Videos (main exercises only)",
  
 
 
  
] as const;


const yearlyBaseFeaturesShred = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "Private Facebook Community",
  
  "**50** Shred Meals",
  
  
  "**3** Workout Programs (3-6 months worth of workouts)",
  "**23** Exercise Tutorial Videos (main exercises only)",
  
 
 
  
] as const;

const yearlyPremiumFeatures = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "Private Facebook Community",
 
  "**200+** BUILD Meals",
  "**15** Workout Programs (12-24 months worth of workouts)",
  "**102** Exercise Tutorial Videos (main exercises + all alternatives)",
  "**48** Weekly Meal Plans",
  "Ab Workout Routine",
  "Personalized training + nutrition",
  "Complimentary BWS Shaker Bottle",
 
] as const;

const yearlyPremiumFeaturesShred = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "Private Facebook Community",
 
  "**200+** Shred Meals",
  "**15** Workout Programs (12-24 months worth of workouts)",
  "**102** Exercise Tutorial Videos (main exercises + all alternatives)",
  "**48** Weekly Meal Plans",
  "Ab Workout Routine",
  "Personalized training + nutrition",
  "Complimentary BWS Shaker Bottle",
 
] as const;

const yearlyPremiumBonusFeatures = [
  
  "Nutrition guidance and macro-tracking",
  "Customized workout adjustments based on your progress and goals.",
  "Progress tracking and performance analytics",
  "Effortless meal logging (AI meal scanner)",
  "Guided Mobility & prehab routines",
] as const;

const yearlyEliteFeatures = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** BUILD Meals",
  "**15** Workout Programs (12-24 months worth of workouts)",
  "**102** Exercise Tutorial Videos (main exercises + all alternatives)",
  "**48** Weekly Meal Plans",
  "Ab Workout Routine",
  "Personalized training + nutrition",
  "Complimentary BWS Shaker Bottle",
  
] as const;

const yearlyEliteFeaturesShred = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Shred Meals",
  "**15** Workout Programs (12-24 months worth of workouts)",
  "**102** Exercise Tutorial Videos (main exercises + all alternatives)",
  "**48** Weekly Meal Plans",
  "Ab Workout Routine",
  "Personalized training + nutrition",
  "Complimentary BWS Shaker Bottle",
  
] as const;

const yearlyEliteBonusFeatures = [
  "Nutrition guidance and macro-tracking",
  "Customized workout adjustments based on your progress and goals.",
  "Progress tracking and performance analytics",
  "Effortless meal logging (AI meal scanner)",
  "Guided Mobility & prehab routines",
  "Bonus Travel Workouts & Nutrition Tips",
  "5-Minute-Meal Recipes",
  "Budget-Friendly Meal Prep Guide",
  "30-Minute Weekly Meal Prep Guide",
  "**48** Weekly Meal Plans",
  "Automatic Grocery List Generator",
] as const;

type YearlyProgramLevels = {
  base: YearlyProgramLevelProps;
  premium: YearlyProgramLevelProps;
  elite: YearlyProgramLevelProps;
}

function getStripeYearlyConfigForUser<T extends ProgramLevelConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  const configItem = _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience 
  );
  if (!!configItem) {
    // if the user is overweight and wants to lose weight or lose fat and build muscle, we show them the beginner program
    if (user.bodytype === 'Overweight' && [Goals.LoseFat, Goals.BuildMuscleAndLoseFat].includes(user.goals)) {
      return {
        ...configItem,
        base: { 
          ...configItem.base,
          buyLink: configItem.base.buyLink.replace('intermediate-unlock', 'beginner-unlock')
        },
        premium: { 
          ...configItem.premium,
          buyLink: configItem.premium.buyLink.replace('intermediate-unlock', 'beginner-unlock')
        },
        elite: {
          ...configItem.elite,
          buyLink: configItem.elite.buyLink.replace('intermediate-unlock', 'beginner-unlock')
        }
      };
    }
  }
  return configItem;
}

const useYearlyProgramLevelConfigs = (user: User): YearlyProgramLevels & { listName: string } => {
  const configItem = getStripeYearlyConfigForUser<ProgramLevelConfig>(
    user,
    YEARLY_TEST_PROGRAM_CONFIG
  );
  if (!configItem) {
    throw new Error("No configuration found for the given user");
  }
  const { base, premium, elite } = configItem;
  const mealText_config =
  configItem.name.includes("Build") || configItem.name.includes("Strong")
    ? "BUILD"
    : "SHRED";
  return {
    base: {
      ...base,
      title: ProgramLevelTitle.Base,
      price: {
        current: 67,
        original: 67,
        durationWeeks: 8,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 7,
      featureHeader: "Try Out BWS",
      features: {
        basic: mealText_config.includes("BUILD")? yearlyBaseFeatures : yearlyBaseFeaturesShred,
        bonus: []
      },
      mobileOrder: 3,
    },
    premium: {
      ...premium,
      title: ProgramLevelTitle.Premium,
      price: {
        current: 119,
        original: 159,
        durationWeeks: 16,
      },
      listName: configItem.listName,
      headline: {
        title: "Most Popular",
        color: "#596DD3",
        background: "#FFFFFF",
      },
      guaranteeDurationDays: 14,
      featureHeader: "Fully Personalized & Guided Experience",
      features: {
        basic: mealText_config.includes("BUILD")? yearlyPremiumFeatures : yearlyPremiumFeaturesShred,
        bonus: yearlyPremiumBonusFeatures,
      },
      mobileOrder: 2,
    },
    elite: {
      ...elite,
      title: ProgramLevelTitle.Elite,
      price: {
        current: 149,
        original: 249,
        durationWeeks: 32,
      },
      listName: configItem.listName,
      headline: {
        title: "Best Value",
        color: "#FFFFFF",
        background: "#596DD3",
      },
      guaranteeDurationDays: 30,
      featureHeader: "Best For Fast Results & Busy Lifestyles",
      features: {
        basic: mealText_config.includes("BUILD")? yearlyEliteFeatures : yearlyEliteFeaturesShred,
        bonus: yearlyEliteBonusFeatures,
      },
      mobileOrder: 1,
    },
    listName: configItem.listName,
  };
};


// BWS Basic , BWS+ Premium , BWS Elite




const MONTHLYYEARLY_TEST_PROGRAM_CONFIG = [
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.BuildMuscle],
    name: 'Male Beginner Build',
    listName: 'Beginner build program plus',
    base: {
      productId: "8159849545976",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735205273848:1?channel=buy_button`,
      name: "BWS Basic Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-build.png`,
    },
    premium: {
      productId: "8159851544824",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159855902968",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Build",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Beginner Lean',
    listName: 'Beginner lean program plus',
    base: {
      productId: "8159927697656",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735212089592:1?channel=buy_button`,
      name: "BWS Basic Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-lean.png`,
    },
    premium: {
      productId: "8159942967544",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8159945556216",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Lean",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Female,
    goal: [Goals.BuildMuscle],
    experience: Experience.Beginner,
    name: "Female Beginner Strong",
    listName: "Beginner strong program plus",
    base: {
      name: "BWS Basic Plan - Strong",
      productId: "8160113131768",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-strong.png`,
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735218413816:1?channel=buy_button`,
    },
    premium: {
      name: "BWS+ Premium Plan - Strong",
      productId: "8018561990904",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
    },
    elite: {
      name: "BWS+ Elite Plan - Strong",
      productId: "8018562121976",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Beginner,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Beginner Shred',
    listName: 'Beginner shred program plus',
    base: {
      productId: "8159897092344",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735185449208:1?channel=buy_button`,
      name: "BWS Basic Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-shred.png`,
    },
    premium: {
      productId: "8159898042616",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159898697976",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Shred",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Male Intermediate Build',
    listName: 'Intermediate build program plus',
    base: {
      productId: "8159889096952",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735193116920:1?channel=buy_button`,
      name: "BWS Basic Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-build-intermediate.png`,
    },
    premium: {
      productId: "8159888179448",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159880642808",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Build.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Female Intermediate Lean',
    listName: 'Intermediate lean program plus',
    base: {
      productId: "8160022364408",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735225098488:1?channel=buy_button`,
      name: "BWS Basic Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-lean-intermediate.png`,
    },
    premium: {
      productId: "8160015450360",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8159958040824",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Lean.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Female,
    experience: Experience.Intermediate,
    goal: [Goals.BuildMuscle],
    name: 'Female Intermediate Strong',
    listName: 'Intermediate strong program plus',
    base: {
      productId: "8160120242424",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735227195640:1?channel=buy_button`,
      name: "BWS Basic Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-strong-intermediate.png`,
    },
    premium: {
      productId: "8160119390456",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Female.jpg`,
    },
    elite: {
      productId: "8160117850360",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Strong.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Female.jpg`,
    },
  },
  {
    gender: Gender.Male,
    experience: Experience.Intermediate,
    goal: [Goals.LoseFat, Goals.BuildMuscleAndLoseFat],
    name: 'Male Intermediate Shred',
    listName: 'Intermediate shred program plus',
    base: {
      productId: "8159920324856",
      buyLink:
        `https://built-with-science.myshopify.com/cart/45735203373304:1?channel=buy_button`,
      name: "BWS Basic Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/basic-shred-intermediate.png`,
    },
    premium: {
      productId: "8159919407352",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/monthly-plan-bws+/intermediate-unlock`,
      name: "BWS+ Premium Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BPremium+Male.jpg`,
    },
    elite: {
      productId: "8159917965560",
      buyLink:
        `${STRIPE_CHECKOUT_SESSION_URL}/create-checkout-session/annual-plan-bws+/intermediate-unlock`,
      name: "BWS+ Elite Plan - Shred.",
      image: `${CDN_ROOT}/images/bws-images-basic/BWS%2BElite+Male.jpg`,
    },
  }
] as const;



const monthlyyearlyBaseFeatures = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**100** Exercise Tutorial Videos",
  
  "**100** Fat Loss Meals",
  
  
  "30-Minute Time Saving Workouts",
  
 
 
  
] as const;


const monthlyyearlyBaseFeaturesShred = [
  "Step-By-Step Training & Nutrition Plan",
  "Gym & Home Workouts",
  "**100** Exercise Tutorial Videos",
  
  "**100** Fat Loss Meals",
  
  
  "30-Minute Time Saving Workouts",
 
  
 
 
  
] as const;

const monthlyyearlyPremiumFeatures = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
 
] as const;

const monthlyyearlyPremiumFeaturesShred = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
 
] as const;

const monthlyyearlyPremiumBonusFeatures = [
  "14 Day Money-Back Guarantee",
  "Weekly Check-Ins",
  "Daily Guidance & Habit Building",
  "Smart Nutrition Coaching",
  "Smart Workout Coaching",
  "AI Food Scanner For Effortless Tracking",
  "Progress Tracking & Analytics",
  
] as const;

const monthlyyearlyEliteFeatures = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
] as const;

const monthlyyearlyEliteFeaturesShred = [
  "Highly Personalized Training & Nutrition Program",
  "Gym & Home Workouts",
  "Private Facebook Community",
  "**200+** Exercise Tutorial Videos",
  "**200+** Fat Loss Meals",
  "30-Minute Time Saving Workouts",
 
  
] as const;

const monthlyyearlyEliteBonusFeatures = [
  "30 Day Money-Back Guarantee",
  "Weekly Check-Ins",
  "Daily Guidance & Habit Building",
  "Smart Nutrition Coaching",
  "Smart Workout Coaching",
  "AI Food Scanner For Effortless Tracking",
  "Progress Tracking & Analytics",
  
] as const;

const monthlyyearlyEliteExtraFeatures = [
  "BWS Shaker Bottle",
  
  "**48** Weekly Meal Plans",
  "Automatic Grocery List Generator",
  "30-Minute Weekly Meal Prep Guide",
  "5-Minute-Meal Recipes",
  "Mobility & Posture Routine",
 
  
] as const;



const monthlyyearlyIntroFeatures = [
  "All-In-One Fitness App",
 
  
 
 
  
] as const;


const monthlyyearlyIntroFeaturesBasic =[
  "Web-Based"
] as const;

type MonthlyYearlyProgramLevels = {
  base: MonthlyYearlyProgramLevelProps;
  premium: MonthlyYearlyProgramLevelProps;
  elite: MonthlyYearlyProgramLevelProps;
}

function getStripeMonthlyYearlyConfigForUser<T extends ProgramLevelConfig>(
  user: User,
  config: ReadonlyArray<T>
) {
  const configItem = _.find(
    config,
    (item) =>
      item.gender === user.gender &&
      _.includes(item.goal, user.goals) &&
      item.experience === user.experience 
  );
  if (!!configItem) {
    // if the user is overweight and wants to lose weight or lose fat and build muscle, we show them the beginner program
    if (user.bodytype === 'Overweight' && [Goals.LoseFat, Goals.BuildMuscleAndLoseFat].includes(user.goals)) {
      return {
        ...configItem,
        base: { 
          ...configItem.base,
        
        },
        premium: { 
          ...configItem.premium,
         
        },
        elite: {
          ...configItem.elite,
         
        }
      };
    }
  }
  return configItem;
}

const useMonthlyYearlyProgramLevelConfigs = (user: User): MonthlyYearlyProgramLevels & { listName: string } => {
  const configItem = getStripeMonthlyYearlyConfigForUser<ProgramLevelConfig>(
    user,
    MONTHLYYEARLY_TEST_PROGRAM_CONFIG
  );
  if (!configItem) {
    throw new Error("No configuration found for the given user");
  }
  const { base, premium, elite } = configItem;
  const mealText_config =
  configItem.name.includes("Build") || configItem.name.includes("Strong")
    ? "BUILD"
    : "SHRED";
  return {
    base: {
      ...base,
      title: "Basic",
      price: {
        current: 97,
        original: 97,
        durationWeeks: 8,
        is_annual:false,
      },
      listName: configItem.listName,
      guaranteeDurationDays: 7,
      featureHeader: "Try Out BWS",
      features: {
        intro:monthlyyearlyIntroFeaturesBasic,
        basic: mealText_config.includes("BUILD")? monthlyyearlyBaseFeatures : monthlyyearlyBaseFeaturesShred,
        bonus: [],
        extra: [],
      },
      mobileOrder: 3,
    },
    premium: {
      ...premium,
      title: "Monthly",
      price: {
        current: 29.99,
        original: 40.99,
        durationWeeks: 16,
        is_annual:false,
      },
      listName: configItem.listName,
     
      guaranteeDurationDays: 14,
      featureHeader: "Fully Personalized & Guided Experience",
      features: {
        intro:monthlyyearlyIntroFeatures,
        basic: mealText_config.includes("BUILD")? monthlyyearlyPremiumFeatures : monthlyyearlyPremiumFeaturesShred,
        bonus: monthlyyearlyPremiumBonusFeatures,
        extra: [],
      },
      mobileOrder: 2,
    },
    elite: {
      ...elite,
      title: "Annual",
      price: {
        current: 15.99,
        original: 16.99,
        durationWeeks: 32,
        is_annual:true,
      },
      listName: configItem.listName,
      headline: {
        title: "MOST POPULAR",
        color: "#FFFFFF",
        background: "#596DD3",
      },
      guaranteeDurationDays: 30,
      featureHeader: "Best For Fast Results & Busy Lifestyles",
      features: {
        intro: monthlyyearlyIntroFeatures,
        basic: mealText_config.includes("BUILD")? monthlyyearlyEliteFeatures : monthlyyearlyEliteFeaturesShred,
        bonus: monthlyyearlyEliteBonusFeatures,
        extra: monthlyyearlyEliteExtraFeatures,
      },
      mobileOrder: 1,
    },
    listName: configItem.listName,
  };
};


export {
  getProgramNameForUser,
  useProgramLevelConfigs,
  useFourMonthProgramLevelConfigs,
  useYearlyProgramLevelConfigs,
  useMonthlyYearlyProgramLevelConfigs,
  LANDING_PAGE_CHOICES,
};
